import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";

import Layout from "../components/Layout";

import SEOImage from "../images/seo-images/home-v2.png";

import "../styles/contact.scss";

const SuccessPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>
          Success | Branch Out Online | Website Design & Development
        </title>
        <meta
          name="description"
          content="Thank you for submitting your contact form, one of the team will be in touch soon."
        />
        <meta property="og:image" content={SEOImage} />
      </Helmet>
      <div className="contact-content-container">
        <h1>Thank You!</h1>
        <p>Your form has successfully been submitted.</p>
        <p>One of the team will be in touch soon.</p>
        <Link to="/" className="success-btn">
          Return home
        </Link>
      </div>
    </Layout>
  );
};

export default SuccessPage;
